import { ICreateUserPayload } from '@/types/user.types';
import { api } from '../baseConfig';

interface CreateUserParams {
    sendMailToNewUser: boolean;
    data: ICreateUserPayload;
}

export const usersQuery = api.injectEndpoints({
    endpoints: (builder) => ({
        getUsers: builder.query<IUsersResponse, IUserPayload>({
            query: (options) => ({
                url: '/immo/v1/user',
                method: 'GET',
                params: options,
            }),
            providesTags: ['users'],
        }),
        getParticularUser: builder.query<IUser, number>({
            query: (id) => ({
                url: `/immo/v1/user/${id}`,
                method: 'GET',
            }),
            providesTags: ['users'],
        }),
        getUserInfo: builder.query({
            query: () => ({
                url: `/mevivo/api/rest/config/userDetails`,
                method: 'GET',
            }),
        }),
        getCurrentUserInfo: builder.query<ICurrentUser, void>({
            query: () => ({
                url: `/immo/v1/user/current`,
                method: 'GET',
            }),
            providesTags: ['user'],
        }),
        createUser: builder.mutation<IUser, CreateUserParams>({
            query: ({ sendMailToNewUser, data: createUserPayload }) => ({
                url: '/immo/v1/user',
                method: 'PUT',
                body: createUserPayload,
                params: {
                    sendMailToNewUser,
                },
            }),
            invalidatesTags: ['users'],
        }),
        updateUser: builder.mutation<IUser, ICreateUserPayload>({
            query: (updateUserPayload) => ({
                url: '/immo/v1/user',
                method: 'POST',
                body: updateUserPayload,
            }),
            invalidatesTags: ['users', 'buildings'],
        }),
        searchUser: builder.query<IUsersResponse, ISearchPayload>({
            query: (searchPayload) => ({
                url: '/immo/v1/user/search',
                method: 'GET',
                params: searchPayload,
            }),
            providesTags: ['users'],
        }),
        deleteUser: builder.mutation<IUser, number>({
            query: (id) => ({
                url: `/immo/v1/user/${id}`,
                method: 'DELETE',
            }),
            invalidatesTags: ['users'],
        }),
    }),
    overrideExisting: true,
});

export const {
    useGetUsersQuery,
    useGetParticularUserQuery,
    useGetUserInfoQuery,
    useCreateUserMutation,
    useUpdateUserMutation,
    useGetCurrentUserInfoQuery,
    useLazyGetUsersQuery,
    useSearchUserQuery,
    useLazySearchUserQuery,
    useDeleteUserMutation,
} = usersQuery;
