import { api } from '../baseConfig';

export interface ChangePasswordDto {
    oldPassword?: string;
    newPassword: string;
    resetToken?: string;
}

export const authQuery = api.injectEndpoints({
    endpoints: (builder) => ({
        authUser: builder.mutation<IAuthResponse, ICredentials>({
            query: (credentials) => ({
                url: '/auth/v1/login',
                method: 'POST',
                body: credentials,
            }),
        }),
        resetPassword: builder.mutation<unknown, { email: string }>({
            query: (credentials) => ({
                url: '/auth/v1/resetPassword',
                method: 'POST',
                params: {
                    email: credentials.email,
                },
            }),
        }),
        changePassword: builder.mutation<unknown, ChangePasswordDto>({
            query: (credentials) => ({
                url: '/auth/v1/changePassword',
                method: 'POST',
                body: {
                    oldPasswordBase64: credentials.oldPassword
                        ? btoa(credentials.oldPassword)
                        : undefined,
                    newPasswordBase64: btoa(credentials.newPassword),
                    passwordResetToken: credentials.resetToken,
                },
            }),
        }),
    }),
});

export const { useAuthUserMutation, useResetPasswordMutation, useChangePasswordMutation } =
    authQuery;
